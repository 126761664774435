<template>
  <li>
    <a @click="changeCurrency(symbol)" class="dropdown-item" href="#">
      {{ symbol }}
    </a>
  </li>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
//import { defineProps } from 'vue';

defineProps(['symbol']);
const emit = defineEmits(['changeCurrency']);

function changeCurrency(parSymbol) {
  emit('changeCurrency', parSymbol);
}
</script>
