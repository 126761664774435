<template>
  <div class="jr-bg-black text-light">
    <!-- <div class="text-light">
      <pre>{{ getAccountGroups.value }}</pre>
    </div> -->

    <div class="ms-2 mt-4 text-warning">
      <h4>Settings</h4>

      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Select currency
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <currency-item
            v-for="item in currencyArray"
            :key="item.symbol"
            :symbol="item.symbol"
            @change-currency="changeCurrency"
          ></currency-item>
        </ul>
      </div>

      <br />

      <base-card>
        <h5>Account links at search</h5>

        <div class="row">
          <div class="col-md-4">
            <li>
              <!-- <div>{{ account1 }}</div> -->
              <account-dropdown
                class="mb-2 me-2 mt-2"
                @onchange="onChangeAcc1"
                :modelValue="account1"
              ></account-dropdown>
            </li>

            <li>
              <account-dropdown
                class="mb-2 me-2 mt-2"
                @onchange="onChangeAcc2"
                :modelValue="account2"
              ></account-dropdown>
            </li>

            <li>
              <account-dropdown
                class="mb-2 me-2 mt-2"
                @onchange="onChangeAcc3"
                :modelValue="account3"
              ></account-dropdown>
            </li>

            <li>
              <account-dropdown
                class="mb-2 me-2 mt-2"
                @onchange="onChangeAcc4"
                :modelValue="account4"
              ></account-dropdown>

              <base-button @click="save">Save</base-button>
            </li>
          </div>
        </div>
      </base-card>

      <div class="text-light" v-if="1 == 1">
        <pre>{{ getSettings }}</pre>
      </div>
    </div>
  </div>
</template>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 0.5rem 0;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
}
</style>

<script setup>
import { onMounted, onUpdated, computed, ref, reactive } from 'vue';
import { getAuth } from 'firebase/auth';
import { useAccountStore } from '../../stores/AccountStore';
import { useSettingStore } from '../../stores/SettingStore';
import { saveToFirebase } from '../../components/utils/firebaseUtils';

import accountDropdown from '../../components/accounts/accountDropdown.vue';
import CurrencyItem from '../../components/settings/CurrencyItem.vue';

let userUid = null;
let accounts = reactive([]);

const account1 = ref('0');
const account2 = ref('0');
const account3 = ref('0');
const account4 = ref('0');
const settingStore = useSettingStore();
const accountStore = useAccountStore();
const currencyArray = [
  { symbol: 'EUR' },
  { symbol: 'USD' },
  { symbol: 'JPY' },
  { symbol: 'none' },
];

onMounted(() => {
  userUid = getAuth().currentUser.uid;
  accounts.value = accountStore.accountsWithGuid;

  if (getSettings.value.appSettings) {
    if (getSettings.value.appSettings.cashAcc1)
      account1.value = getSettings.value.appSettings.cashAcc1;

    if (getSettings.value.appSettings.cashAcc2)
      account2.value = getSettings.value.appSettings.cashAcc2;

    if (getSettings.value.appSettings.cashAcc3)
      account3.value = getSettings.value.appSettings.cashAcc3;

    if (getSettings.value.appSettings.cashAcc4)
      account4.value = getSettings.value.appSettings.cashAcc4;
  }
});
onUpdated(() => {});

function onChangeAcc1(value) {
  account1.value = value;
}

function onChangeAcc2(value) {
  account2.value = value;
}

function onChangeAcc3(value) {
  account3.value = value;
}

function onChangeAcc4(value) {
  account4.value = value;
}

const getSettings = computed(function () {
  const tmp = useSettingStore().settings;
  return tmp;
});

function getSaveObj() {
  let saveObj = {};

  if (account1.value && account1.value != 0) {
    saveObj.cashAcc1 = account1.value;
  }
  if (account2.value && account2.value != 0) {
    saveObj.cashAcc2 = account2.value;
  }
  if (account3.value && account3.value != 0) {
    saveObj.cashAcc3 = account3.value;
  }
  if (account4.value && account4.value != 0) {
    saveObj.cashAcc4 = account4.value;
  }
  return saveObj;
}

function save() {
  saveToFirebase(
    `settings/${userUid}/appSettings`,
    getSaveObj(),
    settingStore.loadSettings
  );
}

function changeCurrency(symbol) {
  localStorage.setItem('currencySymbol', symbol);
  useSettingStore().currencySymbol = symbol;
}
</script>
